import { Component, Vue, Ref } from "vue-property-decorator";
import { checkEmail, checkPassword, isEmpty } from "@helpers";
import { AuthActions } from "@store/modules/auth/types";
import { authModule } from "@store/namespaces";
import { VForm } from "@/types/vuetify";

@Component
export default class ForgotPassword extends Vue {
  @Ref("form") private readonly formRef!: VForm;
  @authModule.Action("restorePassword")
  private readonly restorePasswordAction!: AuthActions["restorePassword"];
  @authModule.Action("checkSecretCode")
  private readonly checkSecretCodeAction!: AuthActions["checkSecretCode"];
  @authModule.Action("changePassword")
  private readonly changePasswordAction!: AuthActions["changePassword"];

  private email = "";
  private confirmationCode = "";
  private loading = false;
  private confirmedCode = false;
  private codeSent = false;
  private codeSeconds = 0;
  private newPassword = "";
  private repeatNewPassword = "";
  private codeIntervalId = 0;
  private incorrectConfirmationCode = false;

  private get fieldRules() {
    return {
      required: (v: string) =>
        !isEmpty(v) || this.$vuetify.lang.t("$vuetify.errors.required"),
      email: (v: string) =>
        checkEmail(v) ||
        this.$vuetify.lang.t("$vuetify.errors.email.incorrect"),
      confirmationCode: () =>
        !this.incorrectConfirmationCode ||
        this.$vuetify.lang.t("$vuetify.errors.invalid_code"),
      password: (v: string) =>
        checkPassword(v) ||
        this.$vuetify.lang.t("$vuetify.errors.password.length"),
      mustMatchPassword: (v: string) =>
        this.newPassword === v ||
        this.$vuetify.lang.t("$vuetify.errors.password.must_match"),
    };
  }

  private get isShowEmailForm() {
    return !this.codeSent;
  }

  private get isShowConfirmationCodeForm() {
    return !this.isShowEmailForm && !this.confirmedCode;
  }

  private get isShowPasswordForm() {
    return !this.isShowConfirmationCodeForm && !this.isShowEmailForm;
  }

  private async changePassword() {
    if (this.loading) return;

    this.loading = true;

    try {
      await this.changePasswordAction({
        secret: this.confirmationCode,
        newPassword: this.newPassword,
      });

      this.loading = false;
      this.$router.replace({ name: "login" });
    } catch (error) {
      this.loading = false;
    }
  }

  private async checkSecretCode() {
    if (this.loading) return;

    this.loading = true;

    try {
      await this.checkSecretCodeAction({
        secret: this.confirmationCode,
      });

      this.loading = false;
      this.confirmedCode = true;
      this.incorrectConfirmationCode = false;
    } catch (error) {
      this.loading = false;
      this.incorrectConfirmationCode = true;
      this.formRef.validate();
    }
  }

  private async restorePassword() {
    if (this.loading) return;

    this.loading = true;
    try {
      const checked = await this.restorePasswordAction({
        email: this.email,
      });

      if (checked) {
        this.codeSent = true;

        this.codeIntervalId = window.setInterval(() => {
          if (this.codeSeconds < 0) {
            window.clearInterval(this.codeIntervalId);
          } else {
            this.codeSeconds--;
          }
        }, 1e3);

        this.codeSeconds = 90;
      } else {
        this.$notify({
          type: "error",
          title: this.$vuetify.lang.t("$vuetify.errors.user_account_not_found"),
        });
      }

      this.loading = false;
    } catch (error) {
      this.$notify({
        type: "error",
        title: this.$vuetify.lang.t(
          "$vuetify.errors.password.max_number_change_requests"
        ),
      });

      this.loading = false;
    }
  }

  private onClickBackButton() {
    if (this.isShowEmailForm) {
      this.$router.replace({ name: "login" });
    } else if (this.isShowConfirmationCodeForm) {
      window.clearInterval(this.codeIntervalId);
      this.codeSent = false;
      this.codeSeconds = 0;
    } else {
      this.confirmedCode = false;
      this.confirmationCode = "";
      this.newPassword = "";
    }
  }

  private onSubmitForm() {
    if (!this.formRef.validate()) return;

    if (this.isShowEmailForm) {
      this.restorePassword();
    } else if (this.isShowConfirmationCodeForm) {
      this.checkSecretCode();
    } else {
      this.changePassword();
    }
  }
}
